// extracted by mini-css-extract-plugin
export var contact_main = "contact-module--contact_main--ZgnrH";
export var blue_bullet = "contact-module--blue_bullet--Z1-K6";
export var contact_header = "contact-module--contact_header--qm2bm";
export var booking = "contact-module--booking--8GO9n";
export var speaking_info = "contact-module--speaking_info--nHJU2";
export var speaking_topic = "contact-module--speaking_topic--mgo7Z";
export var direct_messaging = "contact-module--direct_messaging--eQ6uK";
export var contact_form = "contact-module--contact_form--ZFE+v";
export var contact_meta = "contact-module--contact_meta--QoSnN";
export var contact_data = "contact-module--contact_data--LM+sR";
export var form_buttons = "contact-module--form_buttons--rmzbf";
export var resources_main = "contact-module--resources_main--prEwV";